@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {

  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  font-family: 'Merriweather', serif;
  padding-top: 66px;
  color: purple;
  background: white;
  line-height: 1.4;
 
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

p{
  line-height: 2;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  color: purple;
  background: gold;
  padding: 0.5rem;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  size: 2rem;
  
}
.btn-primary:hover {
  background: whitesmoke;
  color: purple;
  text-decoration: none;
  border: 3px solid purple;
}

.btn-primary:focus {
  background: whitesmoke !important;
  color: purple;
  text-decoration: none;
  border: 3px solid purple !important;
}

.logo-brand{
  height: 80px;
  width: 80px;
}

.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */

/* Navbar */

.nav-icon {
  font-size: 2rem;
  color: purple;
  
}
.nav-link {
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  list-style-type: none;

}
.nav-link a {
  
  display: inline-block;
  text-decoration: none;
  padding: 1rem 0;
  color: purple;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  background: linear-gradient(to bottom, transparent 65%, #FEDD31 65%)no-repeat center left / 0 100%;
  transition: background-size 0.5s ease
}
.nav-link a:hover {
  background-size: 100% 50%;
}

.show-nav {
  height: 100%;
}


@media screen and (min-width: 768px) {

  
  .nav-link {
    height: auto;
     
  }
  
  .nav-link a {
    margin: 0 1rem;
    padding: 0.5rem 0;
    
  }
}
/* end of navbar */


/* Banner */


 .banner {
    
  margin: 0 auto;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: orange;

}

.resort-banner{
  margin: 0 auto;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: orange;
}

#vid {
  position: absolute;
  width: 100%;
  
}

#content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  position: absolute;
  width: 40%;
  background-color: #fff;
  padding: 1rem;
  margin: 1rem;
}

#vid { z-index: 1; } /* behind */
#content { z-index: 2; } /* in front */


.resort-banner, .banner h1 {
  font-size: 1rem;
  padding: 0.5rem auto;

}
.resort-banner, .banner div {
 
  margin: 0 auto;
}
.resort-banner, .banner p {
  font-size: 0.8rem;
  color: purple;
  padding: 0.5rem auto;
}

.logo {
  max-width: 80px;
  max-height: 80px;
  border-radius: 100%;
  margin: 0.5rem auto;
}



@media screen and (min-width: 576px) {

  .resort-banner, .banner h1 {
    font-size: 1rem;
  }

  .resort-banner, .banner p {
    font-size: 0.8rem;

  }

  .logo {
    max-width: 100px;
    max-height: 100px;
  }
  


}
@media screen and (min-width: 992px) {

  .resort-banner, .banner h1 {
    font-size: 1.5rem;
    }
  .resort-banner, .banner p {
    font-size: 1rem;
  }
  .logo {
    max-width: 150px;
    max-height: 150px;
  }
  
}

/* End of Banner */

/* Title */
.section-title {
  text-align: center;
  margin: 4rem auto;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
  display: inline-block;
  background: linear-gradient(to bottom, transparent 65%, orange 65%)no-repeat center left / 0 100%;
  transition: background-size 0.5s ease
}

.section-title h4:hover{
  background-size: 100% 100%;
 }

.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  
}
/* end of Title */
.contact {
  padding: 5rem 0;
  text-align: center;
}

.services, .explore, .tips {
  padding: 5rem 0;
  min-height: 100vh;
  text-align: center;
}


.services, .tips {
  background: gold;
  
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
   font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.services p {
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

 

/*end of services */

/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of featured rooms */

/* room */
.room {
  box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  box-shadow: var(--lightShadow);
  transition: all 0.3s linear;
  transition: var(--mainTransition);
  height: 350px;
  
}
.room:hover {
  box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}

.img-container img {
  width: 100%;
  height: 240px;
  display: block;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
 
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background:gold;
  color: var(--purple);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: all 0.3s linear;
  transition: var(--mainTransition);
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.explore-link, .room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.3s linear;
}

.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .room-link {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

.img-container:hover .explore-link {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}


.explore-info, .room-info {
 
  text-transform: capitalize;
  text-align: center;
  font-weight: 700;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  margin-top: 1rem;
}

.room-status-booked{
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  margin-top: 1rem;
  color: orange;

}

.room-status-available{
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  margin-top: 1rem;
  color: lightseagreen;
}

.explore-details{
  margin-bottom: 2rem;
}
/* end of room  */

/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}
.desc,
.info {
  margin: 1rem 0;
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single room*/

/* roomlist */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */

/*  rooms fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: 3px;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--purple);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms filter*/

/* start of contact form*/

#ContactForm {
  text-align: left;
  padding-left: 1rem;
 }

#ContactForm .buttonWrapper {
  margin-top: 1rem;
  text-align: right;
 
}

#ContactForm .preserveWhiteSpace {
  white-space: pre;
}

#ContactForm .responseMessage {
  margin-right: 1rem;
}

#ContactForm .verticalAlignMiddle {
  vertical-align: middle;
}

.contact-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 2;
 
}

.label {
  color: purple;
}

/* end of contact form*/

/* start of footer*/

.footer {
 
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  margin: 1.5rem auto auto auto;

  }

  


.footer a{
  color: purple;
  text-decoration: none;
}



.footer .scrollToTopBtn {
 
  background-color: gold;
  color: #000;

}

 
/* end of footer*/


